<script setup lang="ts">
    import { defineProps, withDefaults } from 'vue';
    import { GroupProgress } from 'o365.pwa.modules.client.steps.GroupProgress.ts';

    interface IProps {
        syncStepProgress: GroupProgress,
        currentStep: boolean
    }

    const props = withDefaults(defineProps<IProps>() ,{
        currentStep: false
    });
</script>

<script lang="ts">
    export default {
        name: 'GroupProgressOffline'
    }
</script>

<template>
    <h1>{{props.syncStepProgress.title}}</h1>
</template>
